.signin-section {
    background-image: url(../../assets/images/SignInUP/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y: clip;
    min-height: 780px;
}


.signin-container {
    
    height: 100%;
    max-height: 800px;
    background: white;
    border-radius: 50px;
    flex-wrap: nowrap;
    box-shadow: 10px -3px 20px rgba(0, 0, 0, 0.75);
    min-height: 620px;
}

.signin-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
}

.signin-form {
    margin-top: 1rem;
    width: 100%;
    padding: 0 2rem;
}

.container-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
}
.sign-in-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signupSection-container {
    background-image: url(../SignUp/rightSideBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #FF824D;
    
}
.kino-logo-container {
    width: 100%;
}

.kino-logo-container img {
   border-radius: 50px 0 0 0;
}

.kino-logo-container img {
    width: 100%;
}
.not-a-member {
    text-align: center;
}
@media (min-width: 0px) and (max-width: 600px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signin-container{
        height: auto;
    }
    .signin-content {
        height: 100vh;
        width: 100%;
        padding: 1rem;
    }
    .signupSection-container{
        display: none;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin: .5rem;
    }
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signin-content {
        height: 100vh;
        padding: 1rem;
        display: block;
    }
    .signin-container{
        height: auto;
    }
    .signupSection-container{
        display: none;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin: .5rem;
    }
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 960px) and (max-width: 1280px) {
    .signin-container {
        
        margin-top: 4%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }
    .signinTitle {
        font-size: 2rem !important;
    }
    .signupSection-container {
        height: 100vh;
        flex-basis: 100% !important;
        
    }
    .takeMeInDescription {
        margin: 3%;
    }
    .container-content {
        height: 90vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1280px) and (max-width: 1650px) {
    .signin-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }

    .signupSection-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1650px) {
    .signin-container {
        
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signin-content {
        height: auto;
        
    }

    .signupSection-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signin-form {
        margin-top: 0%;
    }
    .socialNetworkTitle {
        text-align: center;
    }
    .not-a-member {
        margin-top: 2%;
    }

}
.signup-section{
    background-image: url(../../assets/images/SignInUP/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    min-height: 857px;
}
.form-conatiner{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.signup-form {
    margin-top: 1rem;
    width: 100%;
    padding: 0 2rem;
}

.signup-container {
    height: 100%;
    max-height: 800px;
    background: white;
    border-radius: 50px;
    flex-wrap: nowrap;
    box-shadow: 10px -3px 20px rgba(0, 0, 0, 0.75);
}

.signup-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    max-width: 1250px;
    padding-bottom: 30px;
}
.already-a-member {
    text-align: center
}

.take-me-in-container {
    background-image: url(../SignUp/rightSideBackground.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 0px 50px 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #FF824D;
    
}

.kino-logo-container {
    width: 100%;
}

.kino-logo-container img {
   border-radius: 50px 0 0 0;
}

.kino-logo-container img {
    width: 100%;
}

.sign-up-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bg-image {
    position: absolute;
    width: 100%;
    bottom: 28%;
    z-index: -10;
}

.kino-logo-container-small img {
    width: 100%;
    border-radius: 50px;
}


.MuiInputLabel-formControl {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 1px;
}


@media (min-width: 0px) and (max-width: 600px) {
    .kino-logo-container img {
        border-radius: 50px;
    }
    .signupTitle {
        font-size: 2rem !important;
    }
    .signup-form {
        margin-top: 0rem;
    }
    .authProviderSignup {
        height: 3rem !important;
        width: 3rem !important;
    }
    .signup-content {
        height: 100vh; 
        width: 100%;
        padding: 1rem;
        
    }
    .signup-container {
        height: auto;
    }
    .container-content {
        height: auto;
    }
    .already-a-member {
        margin: .5rem;
    }
    
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
    .captcha {
        padding: 5px;
        max-height: 6rem;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    .kino-logo-container img {
        border-radius: 50px;
     }
    .signupTitle {
        font-size: 2rem !important;
    }
    .signup-form {
        margin-top: 0rem;
    }
    .authProviderSignup {
        height: 3.5rem !important;
        width: 3.5rem !important;
    }
    .signup-content {
        height: 100vh; 
        padding: 1rem;
        display: block;
    }
    .signup-container {
        height: auto;
    }
    .container-content {
        height: auto;
    }
    .already-a-member {
        margin: .5rem;
    }
    
    .termsDescriptionsMobile {
        margin-top: 0.5rem !important;
    }
    .captcha {
        padding: 3px;
        max-height: 6rem;
    }
    .alternateAuth {
        margin: 0px !important;
    }
    
}

@media (min-width: 960px) and (max-width: 1280px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 4%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }
    .signupTitle {
        font-size: 2rem !important;
    }
    .take-me-in-container {
        height: 100vh;
        flex-basis: 100% !important;
        
    }
    .takeMeInDescription {
        margin: 3%;
    }
    .container-content {
        height: 90vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .already-a-member {
        margin-top: 1%;
    }
    .signup-form {
        margin-top: 0%;
    }
    
}

@media (min-width: 1280px) and (max-width: 1650px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }

    .take-me-in-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
    }
    .already-a-member {
        margin-top: 1%;
    }
}

@media (min-width: 1650px) {
    .signup-container {
        overflow-y: clip;
        margin-top: 2%;
        height: auto;
        max-height: none;
    }
    .signup-content {
        height: auto;
        
    }

    .take-me-in-container {
        height: 93vh;
        flex-basis: 100% !important;
    }
    
    .container-content {
        height: 93vh;
        flex-direction: column;
    }
    .form-conatiner {
        height: 100vh;
        flex-basis: 100% !important;
    }
    .termsDescriptionsMobile {
        margin-top: .5rem !important;
    }
    .signup-form {
        margin-top: 0%;
    }
    .already-a-member {
        margin-top: 4%;
    }
}